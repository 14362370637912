import React, { useEffect, useState } from "react";
import { Button, InputLabel, TextField } from "@material-ui/core";
import moment from "moment";
import { ResponsiveContainer } from "recharts";
import useStyles from "./style";
import TopInfoBox from "./topInfoBox";
import ThankYouReference from "../../../components/ThankYou";
import { NotificationManager } from "react-notifications";
import EndCallCard from "../../../components/EndCallCard";

function ContinueDeclineOperator(props) {
  const classes = useStyles();
  const [messageSubmit, setMessageSubmit] = useState(false);
  const [stage, setStage] = useState(0);
  const [isEndCall, setIsEndCall] = useState(false);

  useEffect(() => {
    if (props.messageOperatorPressed.message) {
      setStage(0);
    }
  }, [props]);

  useEffect(() => {
    props.showResetButton && setMessageSubmit(true);
  }, [props.showResetButton]);

  const handleSubmit = () => {
    if (!props.messageOperatorDetails.message) {
      NotificationManager.warning("Message is required");
      return;
    }

    setIsEndCall(false);
    props.handleDeclinedSubmit();
  };

  const handleEndCall = () => {
    setIsEndCall(true);
    setMessageSubmit(false);
    props.handleEndCall(props.messageOperatorDetails);
  };

  return (
    <ResponsiveContainer>
      <div className="row">
        <div className="col-md-12">
          {props.showResetButton ? (
            <TopInfoBox
              text={
                messageSubmit && !isEndCall
                  ? "Thank you for your call, I will pass on your message straight away and have your call returned as soon as possible."
                  : "Thank you for call"
              }
            />
          ) : (
            <>
              {stage === 0 && props.suspendedFranchisee.leadSuspend == "1" && (
                <TopInfoBox
                  text={`Unfortunately ${props.suspendedFranchisee.name ||
                    props.suspendedFranchisee
                      .memberName} is on leave from ${moment(
                    props.suspendedFranchisee.leadSuspendStartDate
                  ).format("dddd, Do MMMM YYYY")} to ${moment(
                    props.suspendedFranchisee.leadSuspendEndDate
                  ).format("dddd, Do MMMM YYYY")} and the reason is ${props
                    .suspendedFranchisee.leadSuspendReasonID ||
                    props.suspendedFranchisee.leadSuspendReason}`}
                />
              )}

              {stage === 0 && !messageSubmit && (
                <TopInfoBox text="May I take brief details of your message " />
              )}
            </>
          )}
          {!props.showResetButton ? (
            <div className={classes.selectBox}>
              <form>
                <div className="row">
                  {
                    <div className="col-md-12 mt-4">
                      <InputLabel className="mb-2">Message</InputLabel>
                      <TextField
                        multiline
                        rows={4}
                        variant="outlined"
                        name="message"
                        value={props.messageOperatorDetails.message}
                        onChange={props.handleChangeOperator}
                        className={classes.textField}
                        onFocus={props.handlePressOperator}
                        helperText={
                          !props.messageOperatorDetails.message
                            ? "Please enter the Message"
                            : ""
                        }
                      />
                    </div>
                  }

                  <div className="col-md-12 mt-4">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={props.handleBack}
                        >
                          Back
                        </Button>

                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSubmit}
                          className="ml-3"
                          disabled={!props.messageOperatorDetails.message}
                        >
                          Submit
                        </Button>
                      </div>
                      <EndCallCard handleEndCall={handleEndCall} />
                    </div>

                    {props.showResetButton && (
                      <Button
                        variant="contained"
                        className="ml-3"
                        color="default"
                        onClick={props.handleReset}
                      >
                        Reset
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          ) : (
            <ThankYouReference
              referenceNumber={props.callRefNumber}
              handleReset={props.handleReset}
            />
          )}
        </div>
      </div>
    </ResponsiveContainer>
  );
}

export default ContinueDeclineOperator;
