import { Button, InputLabel, TextField } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ResponsiveContainer } from "recharts";
import useStyles from "./style";
import TopInfoBox from "./topInfoBox";
import { handlePhoneNumberValidation } from "../../../appUtility/phoneNumberUtils";
import ThankYouReference from "../../../components/ThankYou";
import { NotificationManager } from "react-notifications";
import EndCallCard from "../../../components/EndCallCard";

function ContinueMessageOperator(props) {
  const classes = useStyles();
  const [messageSubmit, setMessageSubmit] = useState(false);
  const [stage, setStage] = useState(0);
  const [isEndCall, setIsEndCall] = useState(false);

  useEffect(() => {
    if (
      props.messageOperatorPressed.firstName ||
      props.messageOperatorPressed.lastName
    ) {
      setStage(0);
    } else if (
      props.messageOperatorPressed.phoneNumber1 ||
      props.messageOperatorPressed.phoneNumber2
    ) {
      setStage(1);
    } else if (
      props.messageOperatorPressed.suburb ||
      props.messageOperatorPressed.message
    ) {
      setStage(2);
    } else {
    }
  }, [props]);

  useEffect(() => {
    props.showResetButton && setMessageSubmit(true);
  }, [props.showResetButton]);

  const handleSubmit = () => {
    if (!props.messageOperatorDetails.firstName) {
      NotificationManager.warning("First Name is required");
      return;
    }

    if (!props.messageOperatorDetails.phoneNumber1) {
      NotificationManager.warning("Phone Number 1 is required");
      return;
    }

    const isValid = handlePhoneNumberValidation(
      props.messageOperatorDetails.phoneNumber1,
      props.messageOperatorDetails.phoneNumber2
    );
    if (!isValid) {
      return;
    }

    if (!props.messageOperatorDetails.message) {
      NotificationManager.warning("Message is required");
      return;
    }

    setIsEndCall(false);
    props.handleSubmitMessageOperator();
  };

  const handleKeyEnter = (e, next) => {
    if (e.key === "Enter") {
      var nextfield;
      if (next === "message") {
        nextfield = document.querySelector(`textarea[name=${next}]`);
      } else {
        nextfield = document.querySelector(`input[name=${next}]`);
      }
      nextfield.focus();
    }
  };

  const handleEndCall = () => {
    setIsEndCall(true);
    setMessageSubmit(false);
    props.handleEndCall(props.messageOperatorDetails);
  };

  return (
    <ResponsiveContainer>
      <div className="row">
        <div className="col-md-12">
          {props.showResetButton ? (
            <TopInfoBox
              text={
                messageSubmit && !isEndCall
                  ? "Thank you for your call, I will pass on your message straight away and have your call returned as soon as possible."
                  : "Thank you for call"
              }
            />
          ) : (
            <>
              {stage === 0 && props.suspendedFranchisee.leadSuspend == "1" && (
                <TopInfoBox
                  text={`Unfortunately ${props.suspendedFranchisee.name ||
                    props.suspendedFranchisee
                      .memberName} is on leave from ${moment(
                    props.suspendedFranchisee.leadSuspendStartDate
                  ).format("dddd, Do MMMM YYYY")} to ${moment(
                    props.suspendedFranchisee.leadSuspendEndDate
                  ).format("dddd, Do MMMM YYYY")} and the reason is ${props
                    .suspendedFranchisee.leadSuspendReasonID ||
                    props.suspendedFranchisee.leadSuspendReason}`}
                />
              )}

              {stage === 0 && !messageSubmit && (
                <TopInfoBox text="May I have your name please ?" />
              )}
              {stage === 1 && !messageSubmit && (
                <TopInfoBox text="And what is the best number to contact you on ? " />
              )}

              {stage === 2 && !messageSubmit && (
                <TopInfoBox text="May I take brief details of your message and can you also please provide your suburb" />
              )}
            </>
          )}

          {!props.showResetButton ? (
            <div className={classes.selectBox}>
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <InputLabel className="mb-2">First Name</InputLabel>
                    <TextField
                      variant="outlined"
                      name="firstName"
                      value={props.messageOperatorDetails.firstName}
                      onChange={props.handleChangeOperator}
                      className={classes.textField}
                      onFocus={props.handlePressOperator}
                      onKeyUp={(e) => handleKeyEnter(e, "lastName")}
                      helperText={
                        !props.messageOperatorDetails.firstName
                          ? "Please enter the First Name"
                          : ""
                      }
                    />
                  </div>
                  <div className="col-md-6">
                    <InputLabel className="mb-2">Last Name</InputLabel>
                    <TextField
                      variant="outlined"
                      name="lastName"
                      value={props.messageOperatorDetails.lastName}
                      onChange={props.handleChangeOperator}
                      className={classes.textField}
                      onFocus={props.handlePressOperator}
                      onKeyUp={(e) => handleKeyEnter(e, "phoneNumber1")}
                      helperText={
                        !props.messageOperatorDetails.lastName
                          ? "Please enter the Last Name"
                          : ""
                      }
                    />
                  </div>
                  {(props.messageOperatorDetails.firstName ||
                    props.messageOperatorDetails.lastName) && (
                    <div className="col-md-6 mt-4">
                      <InputLabel className="mb-2">Phone Number 1</InputLabel>
                      <TextField
                        variant="outlined"
                        name="phoneNumber1"
                        type="number"
                        value={props.messageOperatorDetails.phoneNumber1}
                        onChange={props.handleChangeOperator}
                        className={classes.textField}
                        onFocus={props.handlePressOperator}
                        onKeyUp={(e) => handleKeyEnter(e, "phoneNumber2")}
                        helperText={
                          !props.messageOperatorDetails.phoneNumber1
                            ? "Please enter the Phone Number 1"
                            : ""
                        }
                      />
                    </div>
                  )}
                  {(props.messageOperatorDetails.firstName ||
                    props.messageOperatorDetails.lastName) && (
                    <div className="col-md-6 mt-4">
                      <InputLabel className="mb-2">Phone Number 2</InputLabel>
                      <TextField
                        variant="outlined"
                        name="phoneNumber2"
                        type="number"
                        value={props.messageOperatorDetails.phoneNumber2}
                        onChange={props.handleChangeOperator}
                        className={classes.textField}
                        onFocus={props.handlePressOperator}
                        onKeyUp={(e) => handleKeyEnter(e, "suburb")}
                        helperText={
                          !props.messageOperatorDetails.phoneNumber2
                            ? "Please enter the Phone Number 2"
                            : ""
                        }
                      />
                    </div>
                  )}

                  {(props.messageOperatorDetails.firstName ||
                    props.messageOperatorDetails.lastName) &&
                    (props.messageOperatorDetails.phoneNumber1 ||
                      props.messageOperatorDetails.phoneNumber2) && (
                      <div className="col-md-12 mt-4">
                        <InputLabel className="mb-2">Message</InputLabel>
                        <TextField
                          multiline
                          rows={4}
                          variant="outlined"
                          name="message"
                          value={props.messageOperatorDetails.message}
                          onChange={props.handleChangeOperator}
                          className={classes.textField}
                          onFocus={props.handlePressOperator}
                          helperText={
                            !props.messageOperatorDetails.message
                              ? "Please enter the Message"
                              : ""
                          }
                        />
                      </div>
                    )}

                  <div className="col-md-12 mt-4">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={props.handleBack}
                        >
                          Back
                        </Button>

                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSubmit}
                          className="ml-3"
                          disabled={
                            !(
                              (props.messageOperatorDetails.firstName ||
                                props.messageOperatorDetails.lastName) &&
                              (props.messageOperatorDetails.phoneNumber1 ||
                                props.messageOperatorDetails.phoneNumber2) &&
                              props.messageOperatorDetails.message
                            )
                          }
                        >
                          Submit
                        </Button>
                      </div>
                      <EndCallCard handleEndCall={handleEndCall} />
                    </div>
                    {props.showResetButton && (
                      <Button
                        variant="contained"
                        className="ml-3"
                        color="default"
                        onClick={props.handleReset}
                      >
                        Reset
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          ) : (
            <ThankYouReference
              referenceNumber={props.callRefNumber}
              handleReset={props.handleReset}
            />
          )}
        </div>
      </div>
    </ResponsiveContainer>
  );
}

export default ContinueMessageOperator;
