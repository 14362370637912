const ENVIRONMENTS = {
  LOCAL: "local",
  STAGING: "staging",
  PRODUCTION: "production",
};

const getEnvironment = () => {
  switch (window.location.hostname) {
    case "localhost":
      return ENVIRONMENTS.LOCAL;
    case "stagingcall.franchise.care":
      return ENVIRONMENTS.STAGING;
    default:
      return ENVIRONMENTS.PRODUCTION;
  }
};

const redirectToHttps = () => {
  if (
    window.location.protocol === "http:" &&
    window.location.hostname !== "localhost"
  ) {
    window.location.href = window.location.href.replace(/^http:/, "https:");
  }
};

const createConfig = (env) => {
  // Always use HTTPS except for localhost
  const protocol =
    env === ENVIRONMENTS.LOCAL ? window.location.protocol : "https:";
  const baseUrl =
    env === ENVIRONMENTS.STAGING
      ? "stagingcall.franchise.care"
      : "call.franchise.care";

  return {
    apiUrl: `${protocol}//${baseUrl}/php/`,
    videoUrl: `${protocol}//${baseUrl}/uploaded/videofile/`,
    paypal: {
      id:
        env === ENVIRONMENTS.PRODUCTION
          ? "AZ1XjgwM4m-dg7L4OlQMf6jOdzq1H_6IDKLBVf7mh7VqIgJPsYSIKN03iC5LO2XtfSoLUac9NF5R0x8v"
          : "sb",
      type: env === ENVIRONMENTS.PRODUCTION ? "live" : "sandbox",
    },
    version: "2.0.1101",
  };
};

redirectToHttps();
const environment = getEnvironment();
export const config = createConfig(environment);
