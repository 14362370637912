import React, { useEffect, useState } from "react";
import { Button, InputLabel, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";
import { ResponsiveContainer } from "recharts";
import useStyles from "./style";
import { httpClient } from "appUtility/Api";
import TopInfoBox from "./topInfoBox";
import ThankYouReference from "../../../components/ThankYou";
import EndCallCard from "../../../components/EndCallCard";

const qs = require("qs");

const SelectFranchiseChange = (props) => {
  const { radios, setPreviousForwardedScenario } = props;

  const classes = useStyles();
  const [currentFranchiseList, setCurrentFranchiseList] = useState([]);
  const [totalFranchiseData, setTotalFranchiseData] = useState([]);
  const [count, setCount] = useState(0);
  const [stage, setStage] = useState(0);

  const [messageSubmit, setMessageSubmit] = useState(false);
  const [isEndCall, setIsEndCall] = useState(false);

  let commpanyType;
  let loginData = JSON.parse(localStorage.getItem("login"));

  useEffect(() => {
    if (props.franchiseChangeFocus.franchise) {
      setStage(0);
    } else if (props.franchiseChangeFocus.message) {
      setStage(1);
    }
  }, [props.franchiseChangeFocus]);

  useEffect(() => {
    setPreviousForwardedScenario(radios);
  }, []);

  useEffect(() => {
    props.showResetButton && setMessageSubmit(true);
  }, [props.showResetButton]);

  const handleFranchisePress = (e) => {
    if (e.key === "Enter") {
      let params = {
        userID: loginData && loginData.uid,
        userCode: loginData && loginData.aCode,
        appID: 1,
        franchiseeName: e.target.value,
      };

      httpClient
        .post(
          "getSuburbs_Postcode.php",
          qs.stringify({
            action: "get_suburbs",
            source: params,
          })
        )
        .then(({ data }) => {
          if (data) {
            setTotalFranchiseData(data);
            let currFralist = [];
            !data.error &&
              data.map((item) => {
                // item.memberNames.map(mem => {
                //   currFralist.push(mem)
                // })
                currFralist.push(item);
              });
            setCurrentFranchiseList(currFralist);
          }
        });
    }
  };

  const handleFranchiseChange = (e, item) => {
    if (item != null) {
      props.setSuspendedFranchisee(item);
      props.setFranchise(item.companyID);

      commpanyType = item.businessType;
      setCount(1);
    }
  };

  const handleBack = () => {
    setStage(0);
    props.handleBack();
  };

  const handleSubmit = () => {
    setIsEndCall(false);
    props.handleSubmitFranchiseChange();
  };

  const handleEndCall = () => {
    setIsEndCall(true);
    setMessageSubmit(false);
    props.handleEndCall(props.enquiryDetails);
  };

  return (
    <ResponsiveContainer>
      <div className="row">
        <div className="col-md-12">
          {props.showResetButton ? (
            <TopInfoBox
              text={
                messageSubmit && !isEndCall
                  ? "Thank you for calling, I will pass on your message straight away and have your call returned as soon as possible."
                  : "Thank you for call"
              }
            />
          ) : (
            <>
              {props.suspendedFranchisee.leadSuspend == "1" && (
                <TopInfoBox
                  text={`Unfortunately ${props.suspendedFranchisee.name ||
                    props.suspendedFranchisee
                      .memberName} is on leave from ${moment(
                    props.suspendedFranchisee.leadSuspendStartDate
                  ).format("dddd, Do MMMM YYYY")} to ${moment(
                    props.suspendedFranchisee.leadSuspendEndDate
                  ).format("dddd, Do MMMM YYYY")} and the reason is ${props
                    .suspendedFranchisee.leadSuspendReasonID ||
                    props.suspendedFranchisee.leadSuspendReason}`}
                />
              )}

              {stage === 0 && !messageSubmit && (
                <TopInfoBox
                  text={
                    count && count > 0
                      ? "Advisory – Please contact your Management Team for any suburb changes as we cannot action this verbally."
                      : "May I have the franchise name please?"
                  }
                />
              )}
              {stage === 1 && !messageSubmit && (
                <TopInfoBox text="Can you please provide me with details of the message?" />
              )}
            </>
          )}

          {!props.showResetButton ? (
            <div className={classes.selectBox}>
              <div className="row">
                <div className="col-md-8 mb-4">
                  <InputLabel style={{ marginBottom: "10px" }}>
                    Franchise Name
                  </InputLabel>
                  <Autocomplete
                    options={currentFranchiseList}
                    getOptionLabel={(option) =>
                      option.memberName +
                      " - " +
                      option.businessType +
                      (option.serviceLocation && " - " + option.serviceLocation)
                    }
                    onFocus={props.handleFranchiseChangeFocus}
                    onChange={handleFranchiseChange}
                    onKeyPress={handleFranchisePress}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="franchise" // Add this line
                        variant="outlined"
                        onFocus={props.handleFranchiseChangeFocus}
                        onBlur={props.handleFranchiseChangeFocusOut} // Add this line
                        helperText="Press Enter after you enter franchisee name to appear franchisee list."
                      />
                    )}
                  />
                </div>
                <div className="col-md-8 mt-4">
                  <InputLabel className="mb-2">Message</InputLabel>
                  <TextField
                    multiline
                    rows={4}
                    variant="outlined"
                    name="message"
                    value={props.enquiryDetails.message}
                    onChange={props.handleEnquiryChange}
                    className={classes.textField}
                    onFocus={props.handleFranchiseChangeFocus}
                    onBlur={props.handleFranchiseChangeFocusOut} // Add this line
                    helperText={
                      !props.enquiryDetails.message
                        ? "Please enter the Message"
                        : ""
                    }
                  />
                </div>

                <div className="col-md-12 mt-3">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleBack}
                      >
                        Back
                      </Button>

                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        className="ml-3"
                        disabled={!props.enquiryDetails.message}
                      >
                        Submit
                      </Button>
                    </div>
                    <EndCallCard handleEndCall={handleEndCall} />
                  </div>
                  {props.showResetButton && (
                    <Button
                      variant="contained"
                      className="ml-3"
                      color="default"
                      onClick={props.handleReset}
                    >
                      Reset
                    </Button>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <ThankYouReference
              referenceNumber={props.callRefNumber}
              handleReset={props.handleReset}
            />
          )}
        </div>
      </div>
    </ResponsiveContainer>
  );
};

export default SelectFranchiseChange;
