import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { ResponsiveContainer } from "recharts";
import useStyles from "./style";
import TopInfoBox from "./topInfoBox";
import ThankYouReference from "../../../components/ThankYou";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { httpClient } from "appUtility/Api";
import { handlePhoneNumberValidation } from "../../../appUtility/phoneNumberUtils";
import EndCallCard from "../../../components/EndCallCard";

const qs = require("qs");

function SaveCall(props) {
  const classes = useStyles();
  const [messageSubmit, setMessageSubmit] = useState(false);
  const [stage, setStage] = useState(0);
  const [isFocused, setIsFocused] = useState(false);

  const { suburbList } = props;

  let loginData = JSON.parse(localStorage.getItem("login"));

  useEffect(() => {
    if (props.saveCallerPressed.firstName || props.saveCallerPressed.lastName) {
      setStage(0);
    } else if (
      props.saveCallerPressed.phoneNumber1 ||
      props.saveCallerPressed.phoneNumber2
    ) {
      setStage(1);
    } else if (props.saveCallerPressed.postCode) {
      setStage(2);
    } else if (props.saveCallerPressed.address) {
      setStage(3);
    }
  }, [props]);

  useEffect(() => {
    props.showResetButton && setMessageSubmit(true);
  }, [props.showResetButton]);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  const handlePostCodePress = (e) => {
    if (e.key === "Enter") {
      var postQuery = {
        userID: loginData && loginData.uid,
        userCode: loginData && loginData.aCode,
        appID: 1,
        postCode: props.postCode,
      };
      httpClient
        .post(
          "getSuburbs_Franchise.php",
          qs.stringify({
            action: "get_suburbs",
            source: postQuery,
          })
        )
        .then((res) => {
          if (res.data.error) {
            props.setPostCodeValid(false);
            props.setSuburbList([]);
            props.setSuburb("");
          } else {
            if (res.data[0] === "No Valid Suburb found on system") {
              NotificationManager.warning("No Valid Suburb found on system");

              props.setSuburbList([]);
              props.setSuburb("");
              props.setPostCodeValid(false);
            } else {
              props.setSuburbList(res.data);
              props.setPostCodeValid(true);

              // Find the first suburb with memberNames not null and auto select the
              // first suburb where franchisee is available
              const firstSuburbWithMembers = res.data.find(
                (suburb) => suburb.memberNames !== null
              );

              if (firstSuburbWithMembers) {
                props.setSuburb(firstSuburbWithMembers.suburbName);
              }
            }
          }
        });
    }
  };

  const handleSave = () => {
    if (!props.saveCaller.firstName) {
      NotificationManager.warning("First Name is required");
      return;
    }

    if (!props.saveCaller.phoneNumber1) {
      NotificationManager.warning("Phone Number 1 is required");
      return;
    }

    const isValid = handlePhoneNumberValidation(
      props.saveCaller.phoneNumber1,
      props.saveCaller.phoneNumber2
    );
    if (!isValid) {
      return;
    }

    if (!props.postCode) {
      NotificationManager.warning("Postcode is required");
      return;
    }

    if (props.postCode && !props.postCodeValid) {
      NotificationManager.warning("Postcode is not valid");
      return;
    }

    if (props.postCode && !props.suburb) {
      NotificationManager.warning("Please select suburb");
      return;
    }

    if (!props.saveCaller.address) {
      NotificationManager.warning("Address is required");
      return;
    }

    props.handleEndCall();
  };

  return (
    <>
      <ResponsiveContainer>
        <div className="row">
          <div className="col-md-12">
            {stage === 0 && !messageSubmit && (
              <TopInfoBox text="May I have your name please ?" />
            )}
            {stage === 1 && !messageSubmit && (
              <TopInfoBox text="And what is the best number to contact you on ? " />
            )}

            {stage === 2 && !messageSubmit && (
              <TopInfoBox
                text="May I have your postcode please?"
                subText="And your suburb please ?"
              />
            )}

            {stage === 3 && !messageSubmit && (
              <TopInfoBox text="May I take your address please ?" />
            )}

            {messageSubmit && <TopInfoBox text="Thank you for your call" />}

            {!props.showResetButton ? (
              <div className={classes.selectBox}>
                <div className="row">
                  <div className="col-md-6">
                    <InputLabel className="mb-2">FirstName</InputLabel>
                    <TextField
                      variant="outlined"
                      name="firstName"
                      value={props.saveCaller.firstName}
                      onChange={props.handleChangeSave}
                      className={classes.textField}
                      onFocus={props.handleChangeSavePressed}
                      helperText={
                        !props.saveCaller.firstName
                          ? "Please enter the First Name"
                          : ""
                      }
                    />
                  </div>

                  <div className="col-md-6">
                    <InputLabel className="mb-2">LastName</InputLabel>
                    <TextField
                      variant="outlined"
                      name="lastName"
                      value={props.saveCaller.lastName}
                      onChange={props.handleChangeSave}
                      className={classes.textField}
                      onFocus={props.handleChangeSavePressed}
                      helperText={
                        !props.saveCaller.lastName
                          ? "Please enter the Last Name"
                          : ""
                      }
                    />
                  </div>

                  {(props.saveCaller.firstName ||
                    props.saveCaller.lastName) && (
                    <div className="col-md-6 mt-4">
                      <InputLabel className="mb-2">Phone Number 1</InputLabel>
                      <TextField
                        variant="outlined"
                        name="phoneNumber1"
                        type="number"
                        value={props.saveCaller.phoneNumber1}
                        onChange={props.handleChangeSave}
                        className={classes.textField}
                        onFocus={props.handleChangeSavePressed}
                        helperText={
                          !props.saveCaller.phoneNumber1
                            ? "Please enter the Phone Number 1"
                            : ""
                        }
                      />
                    </div>
                  )}
                  {(props.saveCaller.firstName ||
                    props.saveCaller.lastName) && (
                    <div className="col-md-6 mt-4">
                      <InputLabel className="mb-2">Phone Number 2</InputLabel>
                      <TextField
                        variant="outlined"
                        name="phoneNumber2"
                        type="number"
                        value={props.saveCaller.phoneNumber2}
                        onChange={props.handleChangeSave}
                        className={classes.textField}
                        onFocus={props.handleChangeSavePressed}
                        helperText={
                          !props.saveCaller.phoneNumber2
                            ? "Please enter the Phone Number 2"
                            : ""
                        }
                      />
                    </div>
                  )}
                  {(props.saveCaller.firstName || props.saveCaller.lastName) &&
                    (props.saveCaller.phoneNumber1 ||
                      props.saveCaller.phoneNumber2) && (
                      <>
                        <div className="col-md-6 mt-4">
                          <InputLabel className="mb-2">Post Code</InputLabel>
                          <TextField
                            variant="outlined"
                            name="postCode"
                            type="number"
                            onChange={(e) => props.handlePostCode(e)}
                            value={props.postCode || ""}
                            className={classes.textField}
                            onFocus={props.handleChangeSavePressed}
                            onKeyPress={handlePostCodePress}
                            helperText={
                              "Please Press Enter after you have inserted Post Code"
                            }
                          />
                        </div>
                        <div className="col-md-6 mt-4">
                          <div>
                            <InputLabel className="mb-2">Suburb</InputLabel>
                            {suburbList && suburbList.length > 0 ? (
                              <FormControl
                                variant="outlined"
                                style={{ width: "100%" }}
                              >
                                <Select
                                  name="suburb"
                                  value={
                                    (typeof props.suburb === "object"
                                      ? props.suburb && props.suburb.suburbName
                                      : props.suburb) || ""
                                  }
                                  onChange={(e) => props.handleSuburb(e)}
                                >
                                  {suburbList &&
                                    suburbList.map((sub) => (
                                      <MenuItem
                                        key={sub.suburbID}
                                        value={sub.suburbName && sub.suburbName}
                                      >
                                        <span>
                                          {sub.suburbName && sub.suburbName}
                                        </span>
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                            ) : (
                              <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="suburb" className="mb-2">
                                  Suburb
                                </InputLabel>
                                <OutlinedInput
                                  id="suburb"
                                  value=""
                                  readOnly // Prevents editing
                                  onFocus={handleFocus}
                                  onBlur={handleBlur}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      {isFocused ? (
                                        <ArrowDropUpIcon />
                                      ) : (
                                        <ArrowDropDownIcon />
                                      )}
                                    </InputAdornment>
                                  }
                                  labelWidth={60} // Ensures proper label alignment
                                  style={{ cursor: "pointer" }} // Adds a pointer cursor to indicate interaction
                                />
                                {!props.suburb && isFocused ? (
                                  <FormHelperText error>
                                    Please enter a Post Code to see available
                                    suburbs.
                                  </FormHelperText>
                                ) : (
                                  ""
                                )}
                              </FormControl>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12 mt-4">
                          <InputLabel className="mb-2">Address</InputLabel>
                          <TextField
                            variant="outlined"
                            name="address"
                            value={props.saveCaller.address}
                            onChange={props.handleChangeSave}
                            className={classes.textField}
                            onFocus={props.handleChangeSavePressed}
                            helperText={
                              !props.saveCaller.address
                                ? "Please enter the Address"
                                : ""
                            }
                          />
                        </div>
                      </>
                    )}

                  <div className="col-md-12 mt-3">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={props.handleBack}
                        >
                          Back
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSave}
                          className="ml-3"
                          disabled={
                            !(
                              (props.saveCaller.firstName ||
                                props.saveCaller.lastName) &&
                              (props.saveCaller.phoneNumber1 ||
                                props.saveCaller.phoneNumber2) &&
                              props.postCode &&
                              (typeof props.suburb === "object"
                                ? props.suburb && props.suburb.suburbName
                                : props.suburb) &&
                              props.saveCaller.address
                            )
                          }
                        >
                          Save
                        </Button>
                      </div>
                      <EndCallCard
                        title="End Call if customer doesn’t prefer to provide
                            details"
                        handleEndCall={props.handleEndCall}
                      />
                    </div>
                    {props.showResetButton && (
                      <Button
                        variant="contained"
                        className="ml-3"
                        color="default"
                        onClick={props.handleReset}
                      >
                        Reset
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <ThankYouReference
                referenceNumber={props.callRefNumber}
                handleReset={props.handleReset}
              />
            )}
          </div>
        </div>
      </ResponsiveContainer>
      <NotificationContainer />
    </>
  );
}

export default SaveCall;
