import {
  Button,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@material-ui/core";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { ResponsiveContainer } from "recharts";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import useStyles from "./style";
import TopInfoBox from "./topInfoBox";
import { httpClient } from "appUtility/Api";
import { handlePhoneNumberValidation } from "../../../appUtility/phoneNumberUtils";
import ThankYouReference from "../../../components/ThankYou";
import EndCallCard from "../../../components/EndCallCard";

const qs = require("qs");

function ContinueServiceCall(props) {
  const classes = useStyles();
  const [stage, setStage] = useState(0);
  const [isFocused, setIsFocused] = useState(false);

  const { suburbList } = props;

  const inputRef = useRef([]);

  let loginData = JSON.parse(localStorage.getItem("login"));

  useEffect(() => {
    if (props.callerPressed.firstName || props.callerPressed.lastName) {
      setStage(0);
    } else if (
      props.callerPressed.phoneNumber1 ||
      props.callerPressed.phoneNumber2
    ) {
      setStage(1);
    } else if (props.callerPressed.postCode) {
      setStage(2);
    } else if (props.callerPressed.address) {
      setStage(3);
    }
  }, [props]);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  const handlePostCodePress = (e) => {
    if (e.key === "Enter") {
      var postQuery = {
        userID: loginData && loginData.uid,
        userCode: loginData && loginData.aCode,
        appID: 1,
        postCode: props.postCode,
      };
      httpClient
        .post(
          "getSuburbs_Franchise.php",
          qs.stringify({
            action: "get_suburbs",
            source: postQuery,
          })
        )
        .then((res) => {
          if (res.data.error) {
            props.setPostCodeValid(false);
            props.setSuburb([]);
            props.setSuburb("");
          } else {
            if (res.data[0] === "No Valid Suburb found on system") {
              NotificationManager.warning("No Valid Suburb found on system");
              props.setSuburbList([]);
              props.setSuburb("");
              props.setPostCodeValid(false);
            } else {
              props.setSuburbList(res.data);
              props.setPostCodeValid(true);

              // Find the first suburb with memberNames not null and auto select the
              // first suburb where franchisee is available
              const firstSuburbWithMembers = res.data.find(
                (suburb) => suburb.memberNames !== null
              );

              if (firstSuburbWithMembers) {
                props.setSuburb(firstSuburbWithMembers.suburbName);
              }
            }
            // props.setSuburb("");
          }
        });
    }
  };

  const handleNext = () => {
    if (!props.callerDetails.firstName) {
      NotificationManager.warning("First Name is required");
      return;
    }

    if (!props.callerDetails.phoneNumber1) {
      NotificationManager.warning("Phone Number 1 is required");
      return;
    }

    const isValid = handlePhoneNumberValidation(
      props.callerDetails.phoneNumber1,
      props.callerDetails.phoneNumber2
    );
    if (!isValid) {
      return;
    }

    if (!props.postCode) {
      NotificationManager.warning("Postcode is required");
      return;
    }

    if (props.postCode && !props.postCodeValid) {
      NotificationManager.warning("Postcode is not valid");
      return;
    }

    if (props.postCode && !props.suburb) {
      NotificationManager.warning("Please select suburb");
      return;
    }

    if (!props.callerDetails.address) {
      NotificationManager.warning("Address is required");
      return;
    }

    props.handleContinue();
  };

  return (
    <>
      <ResponsiveContainer>
        <div className="row">
          <div className="col-md-12">
            {!props.showResetButton && (
              <>
                {stage === 0 &&
                  props.suspendedFranchisee.leadSuspend == "1" && (
                    <TopInfoBox
                      text={`Unfortunately ${props.suspendedFranchisee.name ||
                        props.suspendedFranchisee
                          .memberName} is on leave from ${moment(
                        props.suspendedFranchisee.leadSuspendStartDate
                      ).format("dddd, Do MMMM YYYY")} to ${moment(
                        props.suspendedFranchisee.leadSuspendEndDate
                      ).format("dddd, Do MMMM YYYY")} and the reason is ${props
                        .suspendedFranchisee.leadSuspendReasonID ||
                        props.suspendedFranchisee.leadSuspendReason}`}
                    />
                  )}

                {stage === 0 && (
                  <TopInfoBox text="May I have your name please ?" />
                )}
                {stage === 1 && (
                  <TopInfoBox text="And what is the best number to contact you on ? " />
                )}
                {stage === 2 && (
                  <TopInfoBox
                    text="May I have your postcode please?"
                    subText="And your suburb please ?"
                  />
                )}
                {stage === 3 && (
                  <TopInfoBox text="May I take your address please ?" />
                )}
              </>
            )}
            {props.showResetButton && (
              <TopInfoBox text="Thank you for your call" />
            )}
            {!props.showResetButton ? (
              <div className={classes.selectBox}>
                <form>
                  <div className="row">
                    <div className="col-md-6">
                      <InputLabel className="mb-2">FirstName</InputLabel>
                      <TextField
                        variant="outlined"
                        name="firstName"
                        value={props.callerDetails.firstName}
                        onChange={props.handleChange}
                        className={classes.textField}
                        onFocus={props.handleCallerFocus}
                        helperText={
                          !props.callerDetails.firstName
                            ? "Please enter the First Name"
                            : ""
                        }
                        ref={inputRef}
                      />
                    </div>
                    <div className="col-md-6">
                      <InputLabel className="mb-2">LastName</InputLabel>
                      <TextField
                        variant="outlined"
                        name="lastName"
                        value={props.callerDetails.lastName}
                        onChange={props.handleChange}
                        className={classes.textField}
                        onFocus={props.handleCallerFocus}
                        helperText={
                          !props.callerDetails.lastName
                            ? "Please enter the Last Name"
                            : ""
                        }
                        ref={inputRef}
                      />
                    </div>
                    {(props.callerDetails.firstName ||
                      props.callerDetails.lastName) && (
                      <div className="col-md-6 mt-4">
                        <InputLabel className="mb-2">Phone Number 1</InputLabel>
                        <TextField
                          variant="outlined"
                          name="phoneNumber1"
                          type="number"
                          value={props.callerDetails.phoneNumber1}
                          onChange={props.handleChange}
                          className={classes.textField}
                          onFocus={props.handleCallerFocus}
                          helperText={
                            !props.callerDetails.phoneNumber1
                              ? "Please enter the Phone Number 1"
                              : ""
                          }
                          // ref={`${inputRef}_3`}
                        />
                      </div>
                    )}
                    {(props.callerDetails.firstName ||
                      props.callerDetails.lastName) && (
                      <div className="col-md-6 mt-4">
                        <InputLabel className="mb-2">Phone Number 2</InputLabel>
                        <TextField
                          variant="outlined"
                          name="phoneNumber2"
                          type="number"
                          value={props.callerDetails.phoneNumber2}
                          onChange={props.handleChange}
                          className={classes.textField}
                          onFocus={props.handleCallerFocus}
                          helperText={
                            !props.callerDetails.phoneNumber2
                              ? "Please enter the Phone Number 2"
                              : ""
                          }
                        />
                      </div>
                    )}
                    {(props.callerDetails.firstName ||
                      props.callerDetails.lastName) &&
                      (props.callerDetails.phoneNumber1 ||
                        props.callerDetails.phoneNumber2) && (
                        <>
                          <div className="col-md-6 mt-4">
                            <InputLabel>Post Code</InputLabel>
                            <TextField
                              variant="outlined"
                              name="postCode"
                              type="number"
                              value={props.postCode}
                              onChange={(e) => props.handlePostCode(e)}
                              onFocus={props.handleCallerFocus}
                              onKeyPress={handlePostCodePress}
                              helperText="Please Press Enter after you have inserted your Post Code"
                              margin="normal"
                              fullWidth
                            />
                          </div>
                          <div className="col-md-6 mt-4">
                            <div>
                              <InputLabel className="mb-3">Suburb</InputLabel>
                              {suburbList && suburbList.length > 0 ? (
                                <FormControl
                                  variant="outlined"
                                  style={{ width: "100%" }}
                                >
                                  <Select
                                    name="suburb"
                                    value={
                                      (typeof props.suburb === "object"
                                        ? props.suburb &&
                                          props.suburb.suburbName
                                        : props.suburb) || ""
                                    }
                                    onChange={(e) => props.handleSuburb(e)}
                                  >
                                    {suburbList &&
                                      suburbList.map((sub) => (
                                        <MenuItem
                                          key={sub.suburbID}
                                          value={
                                            sub.suburbName && sub.suburbName
                                          }
                                        >
                                          <span>
                                            {sub.suburbName && sub.suburbName}
                                          </span>
                                        </MenuItem>
                                      ))}
                                  </Select>
                                </FormControl>
                              ) : (
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel htmlFor="suburb" className="mb-3">
                                    Suburb
                                  </InputLabel>
                                  <OutlinedInput
                                    id="suburb"
                                    value=""
                                    readOnly // Prevents editing
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    endAdornment={
                                      <InputAdornment position="end">
                                        {isFocused ? (
                                          <ArrowDropUpIcon />
                                        ) : (
                                          <ArrowDropDownIcon />
                                        )}
                                      </InputAdornment>
                                    }
                                    labelWidth={60} // Ensures proper label alignment
                                    style={{ cursor: "pointer" }} // Adds a pointer cursor to indicate interaction
                                  />
                                  {!props.suburb && isFocused ? (
                                    <FormHelperText error>
                                      Please enter a Post Code to see available
                                      suburbs.
                                    </FormHelperText>
                                  ) : (
                                    ""
                                  )}
                                </FormControl>
                              )}
                            </div>
                          </div>

                          <div className="col-md-12 mt-4">
                            <InputLabel className="mb-2">Address</InputLabel>
                            <TextField
                              variant="outlined"
                              name="address"
                              value={props.callerDetails.address}
                              onChange={props.handleChange}
                              className={classes.textField}
                              onFocus={props.handleCallerFocus}
                              helperText="Please enter the Address"
                              fullWidth
                            />
                          </div>
                        </>
                      )}

                    <div className="col-md-12 mt-3">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={props.handleBack}
                          >
                            Back
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                            className="ml-3"
                            disabled={
                              !(
                                (props.callerDetails.firstName ||
                                  props.callerDetails.lastName) &&
                                (props.callerDetails.phoneNumber1 ||
                                  props.callerDetails.phoneNumber2) &&
                                props.postCode &&
                                props.postCodeValid &&
                                (typeof props.suburb === "object"
                                  ? props.suburb && props.suburb.suburbName
                                  : props.suburb) &&
                                props.callerDetails.address
                              )
                            }
                          >
                            Next
                          </Button>
                        </div>
                        <EndCallCard
                          handleEndCall={() =>
                            props.handleEndCall(props.callerDetails)
                          }
                        />
                      </div>
                      {props.showResetButton && (
                        <Button
                          variant="contained"
                          className="ml-3"
                          color="default"
                          onClick={props.handleReset}
                        >
                          Reset
                        </Button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            ) : (
              <ThankYouReference
                referenceNumber={props.callRefNumber}
                handleReset={props.handleReset}
              />
            )}
          </div>
        </div>
      </ResponsiveContainer>
      <NotificationContainer />
    </>
  );
}

export default ContinueServiceCall;
