import { Button, Card, Typography } from "@material-ui/core";
import React from "react";

const defaultTitle = "If Call drops or customer didn’t want to proceed";

const EndCallCard = (props) => {
  const { title = defaultTitle, handleEndCall } = props;

  return (
    <Card
      style={{
        backgroundColor: "#ff40817d",
        color: "#fff",
        padding: "15px",
        width: "380px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="body1" style={{ marginBottom: "10px" }}>
          {title}
        </Typography>
        <Button variant="contained" color="secondary" onClick={handleEndCall}>
          End Call
        </Button>
      </div>
    </Card>
  );
};

export default EndCallCard;
